body {
    font-family: 'Inter', sans-serif;
    /* You can specify the desired weight like this:
    font-weight: 400;
    */
}

.h-screen__minus-header {
    height: calc(100vh - 80px);
}

.min-h-screen__minus-header {
    min-height: calc(100vh - 78px);
}

.iti__flag {background-image: url("./assets/imgs/flags.png");}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {background-image: url("./assets/imgs/flags@2x.png");}
}

.job-form-height {
    max-height: 1200px;
}

@media screen  and (min-width: 1024px) {
    .job-form-height {
        max-height: 756px;
    }
}

.landing-background {
    background:  linear-gradient(180deg, rgba(183,186,186,0.5) 0%, rgba(183,186,186,0.5) 100%), url("./assets/imgs/hands.webp") center/cover fixed no-repeat;
}

@media screen and (min-width: 768px) {
    .landing-background {
        background: unset;
    }
}

.about-backdrop {
    background: url("assets/imgs/about_backdrop.png") center/cover no-repeat;
}

.about-backdrop-2 {
    background: url("assets/imgs/about_backdrop_2.png") center/cover no-repeat;
}